import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionLogCards = _resolveComponent("ActionLogCards")!
  const _component_Entities = _resolveComponent("Entities")!

  return (_openBlock(), _createBlock(_component_Entities, {
    "show-label": false,
    "display-view": false,
    "view-table": false,
    "view-card": true,
    "enable-filter": false
  }, {
    card: _withCtx(() => [
      _createVNode(_component_ActionLogCards, {
        total: _ctx.page.total,
        "card-data": _ctx.page.data,
        "current-page": _ctx.filter.paginationPage,
        "rows-per-page": _ctx.filter.paginationSize,
        onCurrentChange: _ctx.handleUpdatePage
      }, null, 8, ["total", "card-data", "current-page", "rows-per-page", "onCurrentChange"])
    ]),
    _: 1
  }))
}