
import {computed, defineComponent, ref} from "vue";
import store from "../../store";
import {CompanySelect, LoadFilterObjects, UserSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "../../components/base/common/Entities.vue";
import ActionLogCards from "../actionView/ActionLogCards.vue";

export default defineComponent({
  name: "ActionLogs",
  components: {ActionLogCards, Entities},
  props: {
    groupId: {type: String}
  },
  setup(props) {
    const filterObject = ref({groupId: props.groupId})
    const page = computed(() => store.state.ActionViewModule.page);
    return {
      ...CompanySelect(),
      ...UserSelect(),
      filterObject,
      page,
      ...LoadFilterObjects(Actions.LOAD_ACTION_VIEWS, filterObject.value, ['user'])
    }
  },
  watch: {
    groupId(newVl) {
      this.filterObject.groupId = newVl
      this.updateFilterObject(this.filterObject)
    }
  }
})
